<template>
  <div class="container-fluid">
    <div class="card border-0 shadow-none">
      <div class="card-body">
        <div class="row">
          <div class="col-12 col-md mb-4">
            <h3 class="fw-bold text-success mb-0">Enquiries by Traffic Source</h3>
          </div>
          <div class="col-auto mb-4 ms-auto">
            <date-picker
              ref="datePicker"
              v-model="range"
              is-range
              :popover="{ visibility: 'click', transition: 'fade' }"
              :input-props="{ class: 'form-control form-control-sm' }"
            >
              <template v-slot="{ inputValue, togglePopover }">
                <div class="date-picker-wrap">
                  <input
                    :value="inputValue.start + ' - ' + inputValue.end"
                    v-on:click="togglePopover"
                    class="d-inline form-control disabled"
                    placeholder="End Date"
                    readonly
                  />
                </div> </template
            ></date-picker>
          </div>
        </div>

        <div v-if="!busy" class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Traffic Source</th>
                <th scope="col">Campaign</th>
                <th scope="col">Enquiries</th>
                <th scope="col">Bookings</th>
                <th scope="col">%</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="e in enquiries" :key="e.index">
                <td>{{ e.source | formatString }} </td>
                <td>{{ e.name ? e.name : 'None' | formatString }}</td>
                <td>{{ e.total }}</td>
                <td>{{ e.bookings }}</td>
                <td>{{ e.conversion_perc.toFixed(1) }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <loading v-else class="mb-4"></loading>

      </div>
    </div>
  </div>
</template>

<script>
import Loading from "../../components/loaders/Loading";
import DatePicker from "v-calendar/lib/components/date-picker.umd";

export default {
  data() {
    return {
      busy: false,
      enquiries: [],
      popover: {
        visibility: "focus"
      },
      range: {
        start: this.$route.query.start ? this.$route.query.start : moment().subtract(1, 'months').format("YYYY-MM-DD"),
        end: this.$route.query.end ? this.$route.query.end : new Date()
      }
    };
  },
  watch: {
    range: {
      handler: function(val, old) {
        this.fetchEnquiryData();
      }
    }
  },
  methods: {
    open() {
      this.$refs["datePicker"].$el.querySelector("input").focus();
    },
    fetchEnquiryData() {
      this.enquiries = [];
      this.busy = true;

      this.$axios(
        process.env.VUE_APP_API_URL +
          "/reporting/enquiries-by-traffic-source?start=" +
          moment(this.range.start).format("YYYY-MM-DD") +
          "&end=" +
          moment(this.range.end).format("YYYY-MM-DD")
      )
        .then(({ data }) => {
          let index = 0;
          for (const [key, value] of Object.entries(data)) {
            let trafficSource = key;

            value.forEach((ele) => {
              let campaign = {
                index: index,
                source: trafficSource,
                name: ele.name,
                total: ele.total,
                bookings: ele.bookings,
                conversion_perc: (parseInt(ele.bookings)/ele.total)*100
              }

              this.enquiries.push(campaign);
            });

            index = index++;
          }
        })
        .finally(() => {
         this.busy = false;
        });
    },
  },
  mounted() {
    this.$store.sidebarOpen = false;
    this.fetchEnquiryData();
  },
  filters: {
    formatString(string) {
      return string.replace(/_/g, " ");
    },
  },
  components: {
    DatePicker,
    Loading
  }
};
</script>

<style scoped>
th, td {
  width: 20% !important;
}
</style>